body {
  font-family: 'Open Sans', sans-serif;
}

p {
  font-size: 18px;
}

.jumbotron {
  background: none;
  margin: 0;
  color: #FFF;
  position: relative;
  background-color: rgba(37,37,37,0.5);

  .certs {
    margin-top: 48px;

    .cert {
      display: inline-block;
      margin-right: 20px;

      img {
        height: 120px;
      }
    }
  }

  h1 {
    font-weight: 300;
    margin-bottom: 16px;
  }

  .details {
    margin-top: 48px;
  }

  p {
    margin-top: 48px;
    margin-bottom: 0;
  }
}

.btn {
  margin-right: 16px;
  width: 180px;
  position: relative;
  overflow: hidden;
  border: none;
  height: 46px;

  outline: none !important;
  box-shadow: none !important;

  span {
    display: block;
    text-align: center;
    position: absolute;
    right: 0;
    width: 134px;
    font-weight: 600;
    font-size: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  i {
    position: absolute;
    height: 100%;
    left: 0;
    width: 46px;
    top: 0;
    background: rgba(0,0,0,0.2);
    font-size: 19px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.section {
  padding: 64px 0;

  h2 {
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 0;
    font-weight: 600;
  }

  p {
    margin: 0;
    margin-bottom: 4px;
  }

  a {
    color: #FFF;
    text-decoration: underline;
    font-weight: 600;
  }

  &.type-2 {
    background: #252525;
    color: #FFF;
  }

  &.type-1 {
    background: #31639c;
    color: #FFF;
  }

  &.type-3 {
    background: #4a90e2;
    color: #FFF;
  }

  &.patents {
    background: #252525;
    color: #FFF;
  }

  &.press {
    background: #31639c;
    color: #FFF;
  }

  &.footer {
    background: #252525;
    color: #FFF;
    padding: 30px 0;
  }

  &.research {
    background: #4a90e2;
    color: #FFF;
  }

  &.preprints {
    background: #31639c;
    color: #FFF;
  }

  .ath {
    font-weight: 300;
    font-style: italic;
  }

  .pub-img.pull-left {
    height: 104px;
  }

  .pub-img.pull-right {
    height: 104px;
  }
}

.not-found {
  background: #252525;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #FFF;

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
      font-size: 64px;
      margin-bottom: 32px;
      font-weight: 300;
    }

    p {
      font-size: 24px;
      opacity: 0.8;
      margin-bottom: 16px;
    }

    a {
      color: #FFF;
      font-weight: 500;
    }
  }
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
